* {
  box-sizing: border-box;
}

:root {
  --fc-button-bg-color: #1976d2;
  --fc-button-border-color: #1976d2;
  --fc-button-hover-bg-color: #1769aa;
  --fc-button-hover-border-color: #1769aa;
  --fc-button-active-bg-color: #1769aa;
  --fc-button-active-border-color: #1769aa;
  --grey-darken-1: #757575;
  --grey-lighten-3: #eee;
  --grey-lighten-5: #fafafa;
  --text-black: #000;
  --color-primary: #1976d2;
  --custom-secondary: #424242;
  --shades-white: #fff;
  --black-006: #0009;
  --black-012: #0000001f;
  --black-026: #00000042;
  --black-038: #00000061;
  --black-054: #0000008a;
  --black-087: #000000de;
  --default-boxShadow: 0px 1px 5px 0px #0000001f, 0px 2px 2px 0px #00000024, 0px 1px 1px 0px #0003;
  --default-borderRadius: "4px";
  --fc-button-bg-color: #1976d2;
  --fc-button-border-color: #1976d2;
  --fc-button-hover-bg-color: #1769aa;
  --fc-button-hover-border-color: #1769aa;
  --fc-button-active-bg-color: #1769aa;
  --fc-button-active-border-color: #1769aa;
}

a {
  color: var(--custom-secondary);
}

html {
  margin: 0;
  overflow-y: auto;
}

body {
  background-color: var(--grey-lighten-5);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

iframe {
  border: none;
}

#reservationByDays {
  width: 180px;
  height: 300px;
}

#reservationByHours, #reservationByType {
  max-height: 300px;
}

.w-100 {
  width: 100%;
}

.fullPage {
  background: #fb8c001a;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dropzone {
  border-width: 2px;
  border-color: var(--custom-secondary);
  color: var(--custom-secondary);
  border-style: dashed;
  border-radius: 4px;
  outline: none;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 20px;
  transition: border .24s ease-in-out;
  display: flex;
}

.container {
  background: #fb8c001a;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
}

p#validatePasswordToDelete-helper-text {
  margin-left: 0;
}

.MuiDivider-light {
  color: #fff;
}

.MuiDivider-light:before, .MuiDivider-light:after {
  background-color: #fff;
}

.MuiFormLabel-asterisk {
  color: red;
}

.MuiFormLabel-root {
  color: var(--custom-secondary);
}

.mr-1 {
  margin-right: .5rem;
}

.p-2 {
  padding: .2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.image {
  width: 100%;
  height: auto;
}

.mt-1 {
  margin-top: 1rem !important;
}

.fit-content {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.MuiTooltip-tooltip {
  color: #815b0e !important;
  background-color: #ffe38f !important;
  padding: 7px 15px !important;
  font-size: 13px !important;
}

.MuiTooltip-tooltip span:before {
  color: #ffe38f !important;
}

.highlight {
  filter: hue-rotate(140deg);
}

.carousel {
  height: auto;
}

.carousel-nav-buttons {
  background-color: #ffffff4d !important;
  border-radius: 0 !important;
}

.rdrDateRangePickerWrapper {
  box-shadow: var(--default-boxShadow);
}

.fc-daygrid-event {
  overflow: hidden;
}

.fc .fc-popover {
  z-index: 1;
}

.fc-timegrid-col-events {
  max-width: 95%;
}

.fc-col-header th {
  border-bottom-width: 1px;
}

.fc-daygrid-day {
  height: 120px;
}

.fc-daygrid-day-top {
  justify-content: center;
  margin-bottom: 0;
}

.fc-toolbar-chunk:nth-child(2) {
  align-items: center;
  display: flex;
}

.fc .fc-toolbar {
  flex-direction: row;
}

.fc .fc-button-primary {
  color: var(--fc-button-bg-color);
  background-color: #0000;
  border-color: #0000;
}

.fc .fc-button-primary:hover {
  border-color: var(--fc-button-hover-border-color);
  color: var(--fc-button-bg-color);
  background-color: #0000;
}

.fc .fc-toolbar-title {
  font-size: 1.6em;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
  border-radius: 4px;
}

.fc .fc-button-primary:focus {
  box-shadow: none;
}

tbody {
  background-color: #fff;
}

@media (max-width: 600px) {
  .fc-toolbar-chunk:first-child {
    align-items: center;
    display: none;
  }

  .fc .fc-toolbar-title {
    font-size: 14px;
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    text-align: center;
    margin-left: 0;
  }

  .alert {
    width: 100%;
  }

  .MuiDialogActions-root {
    flex-direction: column;
    gap: 8px;
    margin-left: 0;
  }

  .MuiDialogActions-root > :not(:first-of-type) {
    margin-left: 0 !important;
  }

  .fc-daygrid-day-frame {
    padding: .12rem !important;
  }

  .club-admin {
    margin-top: 35% !important;
  }

  .player {
    margin-top: 10% !important;
  }
}

@media screen and (max-width: 425px) {
  .fc-header-toolbar, .fc-toolbar {
    flex-direction: column-reverse;
    gap: 16px;
  }
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("layers.760a0456.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  pointer-events: auto;
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #757575;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  width: 24px;
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.rdrCalendarWrapper {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  flex-direction: column;
  display: inline-flex;
}

.rdrDateDisplay {
  justify-content: space-between;
  display: flex;
}

.rdrDateDisplayItem {
  text-align: center;
  color: inherit;
  flex: 1;
  width: 0;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: .833em;
}

.rdrDateDisplayItem input {
  text-align: inherit;
}

.rdrDateDisplayItem input:disabled {
  cursor: default;
}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  justify-content: space-between;
  display: flex;
}

.rdrMonthAndYearPickers {
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrMonths {
  display: flex;
}

.rdrMonthsVertical {
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  flex-direction: row;
  display: flex;
}

.rdrMonth {
  width: 27.667em;
}

.rdrWeekDays {
  display: flex;
}

.rdrWeekDay {
  box-sizing: inherit;
  text-align: center;
  flex-basis: 14.2857%;
}

.rdrDays {
  flex-wrap: wrap;
  display: flex;
}

.rdrInfiniteMonths {
  overflow: auto;
}

.rdrDateRangeWrapper {
  -webkit-user-select: none;
  user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
  outline: none;
}

.rdrDateInput .rdrWarning {
  color: red;
  font-size: 1.6em;
  line-height: 1.6em;
  position: absolute;
  top: 0;
  right: .25em;
}

.rdrDay {
  box-sizing: inherit;
  font: inherit;
  cursor: pointer;
  width: 14.2857%;
  position: relative;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span {
  color: #1d2429;
}

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
}

.rdrDateRangePickerWrapper {
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
}

.rdrStaticRanges {
  flex-direction: column;
  display: flex;
}

.rdrStaticRange {
  font-size: inherit;
}

.rdrInputRange {
  display: flex;
}

.rdrCalendarWrapper {
  color: #000;
  font-size: 12px;
}

.rdrDateDisplayWrapper {
  background-color: #eff2f7;
}

.rdrDateDisplay {
  margin: .833em;
}

.rdrDateDisplayItem {
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 4px;
  box-shadow: 0 1px 2px #23394236;
}

.rdrDateDisplayItem input {
  cursor: pointer;
  color: #849095;
  background: none;
  border: 0;
  width: 100%;
  height: 2.5em;
  line-height: 2.5em;
}

.rdrDateDisplayItemActive {
  border-color: currentColor;
}

.rdrDateDisplayItemActive input {
  color: #7d888d;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
}

.rdrMonthAndYearPickers select {
  -webkit-appearance: none;
  appearance: none;
  color: #3e484f;
  cursor: pointer;
  text-align: center;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") right 8px center no-repeat;
  border: 0;
  border-radius: 4px;
  outline: 0;
  padding: 10px 30px 10px 10px;
}

.rdrMonthAndYearPickers select:hover {
  background-color: #00000012;
}

.rdrMonthPicker, .rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton {
  background: #eff2f7;
  border: 0;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  margin: 0 .833em;
  padding: 0;
  display: block;
}

.rdrNextPrevButton:hover {
  background: #e1e7f0;
}

.rdrNextPrevButton i {
  text-align: center;
  border-style: solid;
  width: 0;
  height: 0;
  margin: auto;
  padding: 0;
  display: block;
  transform: translate(-3px);
}

.rdrPprevButton i {
  border-width: 4px 6px 4px 4px;
  border-color: #0000 #34495e #0000 #0000;
  transform: translate(-3px);
}

.rdrNextButton i {
  border-width: 4px 4px 4px 6px;
  border-color: #0000 #0000 #0000 #34495e;
  margin: 0 0 0 7px;
  transform: translate(3px);
}

.rdrWeekDays {
  padding: 0 .833em;
}

.rdrMonth {
  padding: 0 .833em 1.666em;
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  color: #849095;
  font-weight: 400;
  line-height: 2.667em;
}

.rdrDay {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  color: #1d2429;
  background: none;
  border: 0;
  height: 3em;
  padding: 0;
  line-height: 3em;
}

.rdrDay:focus {
  outline: 0;
}

.rdrDayNumber {
  outline: 0;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  display: flex;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 0;
  right: 0;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
}

.rdrDayToday .rdrDayNumber span:after {
  content: "";
  background: #3d91ff;
  border-radius: 2px;
  width: 18px;
  height: 2px;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%);
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #fff;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #ffffffd9;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: currentColor;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 0;
  right: 0;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrStartEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected {
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
  z-index: 1;
  background: #ffffff17;
  border: 0 solid;
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 0;
  right: 0;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0;
}

.rdrDefinedRangesWrapper {
  background: #fff;
  border-right: 1px solid #eff2f7;
  width: 226px;
  font-size: 12px;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentColor;
  font-weight: 600;
}

.rdrStaticRange {
  cursor: pointer;
  background: #fff;
  border: 0;
  border-bottom: 1px solid #eff2f7;
  outline: 0;
  padding: 0;
  display: block;
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #eff2f7;
}

.rdrStaticRangeLabel {
  text-align: left;
  outline: 0;
  padding: 10px 20px;
  line-height: 18px;
  display: block;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRange {
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  text-align: center;
  color: #6c767a;
  border: 1px solid #dee7eb;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  line-height: 30px;
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover {
  color: #333;
  border-color: #b4bfc4;
  outline: 0;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: "";
  background: none;
  border: 1px solid;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0;
  right: 0;
}

.rdrDayPassive {
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview {
  display: none;
}

.rdrDayDisabled {
  background-color: #f8f8f8;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf;
}

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview {
  filter: grayscale() opacity(60%);
}

.rdrMonthName {
  text-align: left;
  color: #849095;
  padding: .833em;
  font-weight: 600;
}

/*# sourceMappingURL=index.f1881bdf.css.map */
